import HTTP from '@/api/http-common';
import { detectSocial } from '@/api/utils';
import * as types from './mutation-types';

const state = {
  loading: false,
  authors: [{
    label: 'все',
    value: '',
  }],
};

const getters = {
  getAuthors: st => st.authors,
};

const actions = {
  searchAuthor({ commit }, text) {
    const query = !!text && !!text.length ? text : '?';
    commit(types.SEARCH_AUTHOR_REQUEST);
    return new Promise((resolve, reject) => {
      HTTP.get(`/suggest/author?q=${query}`)
        .then((res) => {
          commit(types.SEARCH_AUTHOR_REQUEST_SUCCESS, res.data);
          resolve(res);
        })
        .catch((err) => {
          commit(types.SEARCH_AUTHOR_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
  addAuthor({ commit }, item) {
    commit(types.AUTHOR_ADD, item);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  /**
   * Запрос на получение списка
   */
  [types.SEARCH_AUTHOR_REQUEST](st) {
    st.loading = true;
  },
  [types.SEARCH_AUTHOR_REQUEST_SUCCESS](st, data) {
    const arrAuthors = [{
      label: 'все',
      value: '',
    }];
    for (let i = 0; i < data[1].length; i++) {
      /**
       * Формат строки значение.для.поиска@соц.сеть
       * @example kristinamihailova@vk.com
       */
      const arrPlatform = data[1][i].split('@');
      arrAuthors.push({
        label: data[2][i],
        value: arrPlatform[0],
        social: {
          raw: arrPlatform[1],
          ...detectSocial(arrPlatform[1]),
        },
      });
    }

    st.authors = arrAuthors;
    st.loading = false;
  },
  [types.SEARCH_AUTHOR_REQUEST_FAILURE](st, err) {
    st.err = err;
    st.loading = false;
  },
  [types.AUTHOR_ADD](st, item) {
    st.authors.push(item);
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
