<template>
  <footer class='footer'>
    <div class='footer__wrapper'>
      <a class="footer__link" href="/about">Поиск по&nbsp;социальным сетям</a>
      <a class='footer__link footer__personal' href="/personal-data">Защита данных</a>
      <a class='footer__link footer__copyright' href='https://www.kribrum.ru' target='_blank' rel='noopener'>&copy; АО &laquo;Крибрум&raquo;, {{year}}</a>
    </div>
  </footer>
</template>

<script>
/**
 * Футер сайта
 */
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang='scss' scoped>
$block: '.footer';

#{$block} {
  background-color: #4c7482;
  border-bottom: 4px solid #0f3541;
  &__wrapper {
    max-width: 1334px;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    flex: 0 0 auto;

    color: #fff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }
  &__link {
    text-decoration: none;
  }
  &--transparent {
    background: none;
  }
}

@media all {
  #{$block} {
    &__wrapper {
      padding: 8px 16px;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__wrapper {
      flex-direction: row;
      align-items: flex-start;
    }
    &__copyright {
      margin-left: auto;
    }
    &__personal {
      margin-left: 32px;
    }
  }
}
@media all and (min-width: 1366px) {
  #{$block} {
    &__wrapper {
      padding: 8px 0;
    }
  }
}
</style>
