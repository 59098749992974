/**
 * Список стандартных платформ.
 */
export const platforms = [
  {
    label: 'все',
    icon: '',
    value: '',
  },
  {
    label: 'ВКонтакте',
    icon: 'vkontakte-color',
    value: 'vk.com',
  },
  {
    label: 'Facebook',
    icon: 'facebook-color',
    value: 'facebook.com',
  },
  {
    label: 'Одноклассники',
    icon: 'odnoklassniki-color',
    value: 'odnoklassniki.ru',
  },
  {
    label: 'Instagram',
    icon: 'instagram-color',
    value: 'instagram.com',
  },
  {
    label: 'YouTube',
    icon: 'youtube-color',
    value: 'youtube.com',
  },
  {
    label: 'Twitter',
    icon: 'twitter-color',
    value: 'twitter.com',
  },
  {
    label: 'Livejournal',
    icon: 'livejournal-color',
    value: 'livejournal.com',
  },
  {
    label: 'Ответы@Mail.ru',
    icon: 'mailru-color',
    value: 'otvet.mail.ru',
  },
  {
    label: 'Телеграм',
    icon: 'telegram-color',
    value: 'telegram.org',
  }
];
