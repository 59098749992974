<template>
  <div class='menu'>
    <ul class='menu__list'>
      <li
        class='menu__item'
        v-for='item in items'
        :key="item.href">
        <a
          :class='{
            "menu__link": true,
            "menu__link--is-disabled": item.disabled,
            "menu__link--is-active": isActive(item.href),
          }'
          :href='item.href'
          :title='item.text'
          :target='item.target'
          @click='onClick($event, item)'>{{item.text}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * Компонент отображающий главное меню сайта
 */
export default {
  name: 'Menu2',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClick($event, item) {
      $event.preventDefault();
      this.$emit('click');
      return item.disabled ? false : this.$router.push(item.href);
    },
    isActive(link) {
      return link === this.currentPath;
    },
  },
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
  watch: {
    '$route.path': function (path) {
      this.currentPath = path;
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.menu';

#{$block} {
  &__list {
    list-style-type: none;
  }
  &__link {
    display: block;
    position: relative;

    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-decoration: none;
    transition: background-color 0.5s ease, color 0.5s ease;

    cursor: pointer;
    &:before {
      width: 4px;
      height: 100%;

      display: none;
      position: absolute;
      top: 0;
      left: 0;

      border-radius: 0 4px 4px 0;
      background-color: #ffffff;
      content: ' ';
    }
    &:after {
      width: 7px;
      height: 12.59px;

      position: absolute;
      top: calc(50% - 6px);
      right: 16px;

      background-image: url('/img/svg/icon-menu-arrow-right.svg');
      background-repeat: no-repeat;
      background-size: cover;
      content: ' ';
    }
    &--is-active {
      background-color: rgba(#ffffff, 0.05);
      &:before {
        display: block;
      }
      &:after {
        display: none;
      }
    }
    &--is-disabled {
      color: rgba(#ffffff, 0.25);
      &:after {
        opacity: 0.25;
      }
    }
  }
}

@media all {
  #{$block} {
    &__link {
      padding: 14px 16px;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__list {
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;

      &:before {
        width: 100%;
        height: 2px;
        position: absolute;
        top: calc(100% - 4px);
        left: 0;
        background-color: #344f5e;
        content: ' ';
      }
    }
    &__item {
      margin: 0 8px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    &__link {
      padding: 8px;
      &:before,
      &:after {
        display: none;
      }
      &--is-active {
        background: none;
        position: relative;
        &:after {
          width: 100%;
          height: 4px;

          display: block;
          position: absolute;
          left: 0;
          top: calc(100% - 5px);

          background-color: #fff;
          border-radius: 2px;
          content: ' ';
        }
      }
    }
  }
}


</style>
