/** Articles */
/** Get all articles */
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_REQUEST_SUCCESS = 'SEARCH_REQUEST_SUCCESS';
export const SEARCH_REQUEST_FAILURE = 'SEARCH_REQUEST_FAILURE';

export const RESET_CURRENT_QUERY = 'RESET_CURRENT_QUERY';
export const UPDATE_PREV_QUERY = 'UPDATE_PREV_QUERY';

/** Total */
export const TOTAL_SET = 'TOTAL_SET';
export const TOTAL_RESET = 'TOTAL_RESET';

/** Общее количество сообщений */
export const COUNT_SET = 'COUNT_SET';
export const COUNT_RESET = 'COUNT_RESET';

/** Максимальное количество сообщений */
export const LIMIT_SET = 'LIMIT_SET';
export const LIMIT_RESET = 'LIMIT_RESET';

/** Количество сообщений на странице */
export const MAX_PER_PAGE_SET = 'MAX_PER_PAGE_SET';
export const MAX_PER_PAGE_RESET = 'MAX_PER_PAGE_RESET';


/** Get all articles */
export const SEARCH_POST_REQUEST = 'SEARCH_POST_REQUEST';
export const SEARCH_POST_REQUEST_SUCCESS = 'SEARCH_POST_REQUEST_SUCCESS';
export const SEARCH_POST_REQUEST_FAILURE = 'SEARCH_POST_REQUEST_FAILURE';

/** update_param */
export const UPDATE_PARAM = 'UPDATE_PARAM';

/** update_count */
export const UPDATE_COUNT = 'UPDATE_COUNT';

export const SEARCH_DUBLICATES_REQUEST = 'SEARCH_DUBLICATES_REQUEST';
export const SEARCH_DUBLICATES_REQUEST_SUCCESS = 'SEARCH_DUBLICATES_REQUEST_SUCCESS';
export const SEARCH_DUBLICATES_REQUEST_FAILURE = 'SEARCH_DUBLICATES_REQUEST_FAILURE';
