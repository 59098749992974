import HTTP from '@/api/http-common';
import { platforms } from '@/data/platforms';
import * as types from './mutation-types';
import { detectSocial } from '@/api/utils';

const state = {
  loading: false,
  platforms,
  query: '',
};

const getters = {
  getPlatforms: st => st.platforms,
};

const actions = {
  searchPlatform({ commit }, text) {
    commit(types.SEARCH_PLATFORM_REQUEST);
    return new Promise((resolve, reject) => {
      HTTP.get(`/suggest/platform?q=${text}`)
        .then((res) => {
          commit(types.SEARCH_PLATFORM_REQUEST_SUCCESS, res.data);
          resolve(res);
        })
        .catch((err) => {
          commit(types.SEARCH_PLATFORM_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
  addPlatform({ commit }, item) {
    commit(types.SEARCH_PLATFORM_ADD, item);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  /**
   * Запрос на получение списка
   */
  [types.SEARCH_PLATFORM_REQUEST](st) {
    st.loading = true;
  },
  [types.SEARCH_PLATFORM_REQUEST_SUCCESS](st, data) {
    const arrPlatforms = [{
      label: 'все',
      icon: '',
      value: '',
    }];
    for (let i = 0; i < data[1].length; i++) {
      const obSocial = detectSocial(data[1][i]);
      const icon = obSocial.code === 'other' ? 'explorer' : obSocial.code;
      arrPlatforms.push({
        label: data[2][i],
        value: data[1][i],
        icon,
      });
    }

    st.platforms = arrPlatforms;
    st.loading = false;
  },
  [types.SEARCH_PLATFORM_REQUEST_FAILURE](st) {
    st.platforms = platforms;
    st.loading = false;
  },
  [types.SEARCH_PLATFORM_ADD](st, item) {
    st.platforms.push(item);
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
