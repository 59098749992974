import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from './plugins/logger';

import auth from './modules/auth';
import authLogin from './modules/auth/login';
import authForgot from './modules/auth/forgot';
import authLogout from './modules/auth/logout';
import authRegister from './modules/auth/register';
import authChangePassword from './modules/auth/changePassword';

import user from './modules/user';
import userInfo from './modules/user/info';
import search from './modules/search';
import author from './modules/author';
import platform from './modules/platform';
import period from './modules/period';
import path from './modules/path';
import rating from './modules/rating';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  prevPath: '',
  modules: {
    auth: {
      ...auth,
      modules: {
        authLogin,
        authForgot,
        authLogout,
        authRegister,
        authChangePassword,
      },
    },
    user: {
      ...user,
      modules: {
        userInfo,
      },
    },
    search,
    path,
    platform,
    period,
    author,
    rating,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

const data = window.__INITIAL_STATE__;
if (data) {
  const newState = {
    ...store.state,
    auth: {
      ...store.state.auth,
      isLoggedIn: data.isLoggedIn,
    },
    user: {
      ...store.state.user,
      avatarSrc: data.avatarSrc,
      name: data.name,
      isAnonymous: data.isAnonymous,
      hasComments: data.hasComments,
    },
  };
  store.replaceState(newState);
}

export default store;
