/**
 * Список временных отрезков использующихся в фильтре
 */
export const periods = [
  {
    label: 'cегодня',
    value: 'today',
  },
  {
    label: '3 дня',
    value: '3days',
  },
  {
    label: 'неделя',
    value: '1week',
  },
  {
    label: 'месяц',
    value: '1month',
  },
  {
    label: 'за все время',
    value: 'all',
  }
];
