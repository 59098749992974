import qs from 'qs';
import HTTP from '@/api/http-common';
import * as types from './mutation-types';

const state = {
  query: {
    query: '',
    platform: '',
    show: '',
    page: 1,
    on_page: 50,
  },
  result: {
    count: 0,
    code: 0,
    limit: 0,
    maxPerPage: 0,
    total: 0,
  },
  isLoading: false,
  error: '',
};

const getters = {

};

const actions = {
  rating({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.RATING_REQUEST);
      HTTP.post('/rating', qs.stringify(state.query))
        .then((res) => {
          commit(types.RATING_REQUEST_SUCCESS);
          resolve(res);
        })
        .catch((err) => {
          commit(types.RATING_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  [types.RATING_REQUEST](st) {
    st.isLoading = true;
  },
  [types.RATING_REQUEST_SUCCESS](st) {
    st.isLoading = false;
  },
  [types.RATING_REQUEST_FAILURE](st, error) {
    st.isLoading = false;
    st.error = error;
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
