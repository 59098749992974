var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    page: true,
    "page--404": _vm.is404
  }},[_c('Header',{class:{
      "page__header": true,
      "header--transparent": _vm.is404,
    }}),(_vm.isMain)?_c('MainHead',{class:{
      "marginHead": _vm.isMain,
    }}):_vm._e(),_c('Body',{class:{
      "page__body": true,
      "wrapper": !_vm.isStat,
      "marginHead": !_vm.isMain,
      "marginHead--search": !_vm.isMain && _vm.isSearch,
    }},[_vm._t("default")],2),_c('Footer',{class:{
      "page__footer": true,
      "footer--transparent": _vm.is404
    }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }