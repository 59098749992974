<template>
  <header class='header'>
    <div class='header__wrapper'>
      <Logo
        class='header__logo'
        :isMobile='isMobile'
        v-if='isMainPage && !isInnerMainPage'/>
      <a
        class='header__logo'
        href='/'
        @click='toPage($event, "/")'
        v-else>
        <Logo :isMobile='isMobile'/>
      </a>
      <template v-if='isMobile'>
        <a
          class='header__search h-search'
          @click='toggle("Search", true)'
          v-if='isUserAuthorized && !isMainPage && isSearchPage'>
          <span class='h-search__text'>Поиск</span>
          <Icon class='h-search__icon' :icon='(showSearch) ? "arrow-up": "arrow-down"'/>
        </a>
        <a
          class='header__burger'
          @click='toggle("Menu")'>
          <Icon
            class='header__showMenu'
            icon='menu'/>
        </a>
        <portal to='mobile' v-if='isSearchPage'>
          <div class='popup popup--autoSize popup--search-js-size'>
            <div class='popup__wrapper'>
              <div class='popup-search'>
                <Logo
                  class='popup-search__logo'
                  :isMobile='true'/>
                <a
                  class='popup-search__search h-search'
                  @click='toggle("Search", true)'
                  v-if='isUserAuthorized'>
                  <span class='h-search__text'>Поиск</span>
                  <Icon class='h-search__icon' :icon='(showSearch) ? "arrow-up": "arrow-down"'/>
                </a>
                <a
                  class='popup-search__burger'
                  @click='toggle("Menu")'>
                  <Icon
                    class='header__showMenu'
                    icon='menu'/>
                </a>
                <SearchForm
                  class='popup-search__form'
                  @click='toggle("Search")'
                  :isFormOpen='isFormOpen'
                  />
              </div>
            </div>
          </div>
        </portal>
        <portal to='mobile' v-if='showMenu'>
          <div class='popup'>
            <div class='popup__wrapper'>
              <div class='popup-menu'>
                <Logo
                  class='popup-menu__logo'
                  :isMobile='false'/>
                <a
                  class='popup-menu__burger'
                  @click='toggle("Menu")'>
                  <Icon
                    class='header__showMenu'
                    icon='menu-close'/>
                </a>
                <Menu
                  class='popup-menu__menu'
                  :items='menuItems'
                  @click='toggle("Menu")'/>
                <template v-if='isUserAuthorized && !isUserAnonymous'>
                  <Profile
                    class='popup-menu__profile'
                    :name='userName'
                    :avatarSrc='userAvatar'/>
                  <HeaderLink
                    :class='{
                      "header__link": true,
                      "header__link--is-authorized": isUserAuthorized,
                    }'
                    icon='logout'
                    link='#'
                    @click='logout'/>
                </template>
                <template v-else>
                  <HeaderLink
                    class='header__link'
                    icon='login'
                    text='Войти'
                    link='/login'
                    @click='toPage'/>
                </template>
              </div>
            </div>
          </div>
        </portal>
      </template>
      <template v-else>
        <Menu
        class='header__menu'
        :items='menuItems'/>
        <template v-if='isUserAuthorized && !isUserAnonymous'>
          <Profile
            class='header__profile'
            :name='userName'
            :avatarSrc='userAvatar'/>
          <HeaderLink
            :class='{
              "header__link": true,
              "header__link--is-authorized": isUserAuthorized,
            }'
            icon='logout'
            link='#'
            @click='logout'/>
        </template>
        <template v-else>
          <HeaderLink
            class='header__link'
            icon='login'
            text='Войти'
            link='/login'
            @click='toPage'/>
        </template>
        <SearchForm
          class='header__form'
          v-if='isSearchPage'/>
      </template>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { recalcGradient } from '@/api/utils';
import Logo from '@/components/new/logo/logo.vue';
import Menu from '@/components/new/menu/menu.vue';
import Profile from '@/components/new/profile/profile.vue';
import HeaderLink from '@/components/new/headerLink/headerLink.vue';
import Icon from '@/components/ui/icon/icon.vue';
import SearchForm from '@/components/ui/searchForm/searchForm.vue';

/**
 * Header сайта
 */
export default {
  name: 'Header',
  components: {
    Logo,
    Menu,
    Profile,
    HeaderLink,
    Icon,
    SearchForm,
  },
  created() {
    this.onResize();
    const events = ['resize', 'scroll'];
    for (const event of events) {
      const handlerName = `on${event[0].toUpperCase() + event.slice(1)}`;
      this.listeners[event] = window.addEventListener(event, this[handlerName]);
    }

    if (this.isUserAnonymous) {
      this.menuItems[0] = {
        ...this.menuItems[0],
        href: '/main',
        to: '/main',
      };
    }
  },
  destroyed() {
    const events = ['resize', 'scroll'];
    for (const event of events) {
      const handlerName = `on${event[0].toUpperCase() + event.slice(1)}`;
      window.removeEventListener(event, this.listeners[handlerName]);
    }
  },
  data() {
    return {
      isMobile: true,
      isFormOpen: false,
      showMenu: false,
      showSearch: false,
      menuItems: [{
        text: 'Поиск',
        href: '/search',
        to: '/search',
      }, {
        text: 'Рейтинг авторов',
        href: '/rating',
        disabled: false,
      }, {
        text: 'Статистика фраз',
        href: '/statistic',
        disabled: false,
      }],
      listeners: {
        resize: '',
        scroll: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'isUserAuthorized',
      'getUserInfo',
      'getAvatar',
      'isUserAnonymous',
    ]),
    userName() {
      return this.getUserInfo.name;
    },
    userAvatar() {
      return this.getAvatar;
    },
    isMainPage() {
      return this.$route.path === '/' || this.$route.path === '/main';
    },
    isInnerMainPage() {
      return this.$route.path === '/main';
    },
    isSearchPage() {
      return this.$route.path === '/search' || this.$route.name === 'doubles';
    },
  },
  methods: {
    /**
     *
     */
    onResize() {
      this.isMobile = window.outerWidth < 960;
      this.isFormOpen = !(window.outerWidth < 960);
    },
    onScroll() {
      recalcGradient();
    },
    toPage($event, link) {
      $event.preventDefault();
      return link.length ? this.$router.push(link) : false;
    },
    logout($event) {
      $event.preventDefault();
      return this.$store.dispatch('logout')
        .then(() => {
          this.$store.dispatch('userInfo');
          this.$store.commit('CLEAR_PREV_PATH');
          this.$router.push('/');
        })
        .catch(err => alert(err));
    },
    toggle(key, isChange) {
      if (!key) {
        return false;
      }

      const nextValue = !this[`show${key}`];
      if (key === 'Search' && nextValue) {
        this.showMenu = false;
      }
      if (key === 'Menu' && nextValue) {
        this.showSearch = false;
      }
      if (isChange && key === 'Search') {
        this.isFormOpen = !this.isFormOpen;
      }

      this[`show${key}`] = !this[`show${key}`];
      return true;
    },
  },
  watch: {
    $route() {
      window.setTimeout(() => {
        recalcGradient();
      }, 100);
    },
  },
};
</script>

<style scoped lang='scss'>
$block: '.header';
#{$block} {
  background-image: linear-gradient(150deg, #5a7380, #475a67 11%, #33404d 24%, #2c3643 33%, #5a7380);
  background-size: 100% 1000px;
  background-position: 0 0;
  border-top: 4px solid #5a7380;
  &--transparent {
    background: none;
  }
  &__wrapper {
    max-width: 1334px;
    margin: 0 auto;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
  }

  &__logo {
    flex: 0 0 auto;
  }
}

@media all {
  #{$block} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    &__wrapper {
      padding: 8px 16px;
    }
    &__logo {
      margin: 0 auto 0 0;
    }
    &__search {
      margin: 0 auto;
    }
    &__showMenu {
      height: 24px;
      display: block;
      cursor: pointer;
    }
    &__burger {
      margin: 0 0 0 auto;
    }
  }
}

@media all and (min-width: 960px) {
  #{$block} {
    &__logo {
      margin-right: auto;
      flex: 0 0 auto;
    }
    &__menu {
      margin: 0 auto;
      flex: 0 0 auto;
    }
    &__profile {
      margin-left: auto;
      flex: 0 0 auto;
    }
    &__link {
      margin-left: auto;
      flex: 0 0 auto;
      &--is-authorized {
        margin-left: 16px;
      }
    }
    &__form {
      margin: 8px auto 0 auto;
      padding: 0 196px 0 180px;
      flex: 0 0 100%;
    }
  }
}
@media all and (min-width: 1366px) {
  #{$block} {
    &__wrapper {
      padding: 8px 0;
    }
  }
}

.h-search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  &__icon {
    width: 18px;
    margin-left: 8px;
  }
}


.popup-search {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  &__logo {
    margin: 0 auto 0 0;
    flex: 0 0 auto;
  }
  &__search {
    margin: 0 auto;
    flex: 0 0 auto;
  }
  &__burger {
    margin: 0 0 0 auto;
    flex: 0 0 auto;
  }
  &__form {
    width: 100%;
    margin: 8px 0 0 0;
  }
}
.popup-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  &__logo {
    margin: 0 auto 0 0;
    flex: 0 0 auto;
  }
  &__burger {
    margin: 0 0 0 auto;
    flex: 0 0 auto;
  }
  &__menu {
    margin: 32px 0;
    flex: 0 0 calc(100% + 32px);
    position: relative;
    left: -16px;
  }
}
</style>
