<template>
  <div class='logo'>
    <Icon
      :class='{
        "logo__img": true,
        "logo__img--desktop": !isMobile,
      }'
      :icon='iconName'/>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon/icon.vue';
/**
 * Логотип
 */
export default {
  name: 'Logo',
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Icon,
  },
  computed: {
    iconName() {
      return this.isMobile ? 'logo-mobile' : 'logo-new';
    },
  },
};
</script>

<style lang='scss'>
$block: '.logo';

#{$block} {
  &__img {
    display: block;
  }
}

@media all {
  #{$block} {
    &__img {
      height: 40px;
    }
  }
}
</style>
