import * as types from './mutation-types';

const state = {
  prevPath: '',
};

const getters = {
  getPrevPath: st => st.prevPath,
};

const actions = {
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.SET_PREV_PATH](st, path) {
    st.prevPath = path;
  },
  [types.CLEAR_PREV_PATH](st) {
    st.prevPath = '';
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
