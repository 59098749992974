import { periods } from '@/data/timePeriods';

const state = {
  periods,

};

const getters = {
  getPeriods: st => st.periods,
};

const actions = {

};

/* eslint-disable no-param-reassign */
const mutations = {

};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
