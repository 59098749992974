/**
 * Функция проверяет находится ли в объекте ob ключ fieldName и не пустой ли он
 * @param {Object} ob - объект в котором ищется ключ
 * @param {String} fieldName - название поля
 * @returns {boolean};
 */
export function isExist(ob, fieldName) {
  return !(!ob || !ob[fieldName] || typeof ob[fieldName] === 'undefined');
}

/**
 * Функция возвращает идентификатор социальной сети или "Другое"
 * @param platform - Идентификатор платформы
 * @return
 */
export function detectSocial(platform) {
  let currentSocial = {
    name: 'Другое',
    code: 'other',
  };
  const arrSocials = [{
    search: /(^vk.com)|(^vkontakte.ru)/i,
    name: 'Вконтакте',
    code: 'vkontakte-color',
  }, {
    search: /(^twitter.com)/i,
    name: 'Twitter',
    code: 'twitter-color',
  }, {
    search: /(^youtube.com)/i,
    name: 'YouTube',
    code: 'youtube-color',
  }, {
    search: /(^facebook.com)/i,
    name: 'Facebook',
    code: 'facebook-color',
  }, {
    search: /(^instagram)/i,
    name: 'Instagram',
    code: 'instagram-color',
  }, {
    search: /(^ok.com)|(^odnoklassniki.ru)/i,
    name: 'Одноклассники',
    code: 'odnoklassniki-color',
  }, {
    search: /(^livejournal.com)/i,
    name: 'Livejournal',
    code: 'livejournal-color',
  }, {
    search: /(^otvet.mail.ru)/i,
    name: 'Ответы@Mail.ru',
    code: 'mailru-color',
  }, {
    search: /(^telegram.org)/i,
    name: 'Телеграм',
    code: 'telegram-color',
  }];

  for (let i = 0; i < arrSocials.length; i += 1) {
    const obSocial = arrSocials[i];
    if (obSocial.search.test(platform)) {
      currentSocial = {
        name: obSocial.name,
        code: obSocial.code,
      };
      break;
    }
  }

  return currentSocial;
}

/**
 * Простая функция для определения склонения числа
 * @param {*} number
 * @param {*} titles
 */
export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  let res = 0;
  if (number % 100 > 4 && number % 100 < 20) {
    res = 2;
  } else {
    res = cases[(number % 10 < 5) ? number % 10 : 5];
  }

  return titles[res];
}

/**
 * Функция пересчитывает размеры шапки
 */
export function recalcGradient() {
  const $headerWrapper = document.querySelector('.header');
  const $mainHead = document.querySelector('.main-head');
  const scrollFromTop = window.scrollY;

  if (!$headerWrapper || !$mainHead) {
    return;
  }

  const headerHeight = $headerWrapper.offsetHeight;
  const mainHeadHeight = $mainHead.offsetHeight;
  let gradientHeight = mainHeadHeight + (headerHeight * 2);
  gradientHeight = gradientHeight !== 1000 && gradientHeight > 1000 ? gradientHeight : 1000;

  const isHeaderBelowHead = scrollFromTop < mainHeadHeight;
  const headerWrapperPosition = isHeaderBelowHead ? -scrollFromTop : -mainHeadHeight;
  $headerWrapper.style.backgroundSize = `100% ${gradientHeight}px`;
  $headerWrapper.style.backgroundPosition = `0px ${headerWrapperPosition}px`;

  const mainHeadPosition = -headerHeight;
  $mainHead.style.backgroundSize = `100% ${gradientHeight}px`;
  $mainHead.style.backgroundPosition = `0 ${mainHeadPosition}px`;
}
