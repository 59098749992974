import * as types from './mutation-types';

const state = {
  isLoggedIn: false,
};

const getters = {
  isUserAuthorized: st => st.isLoggedIn,
};

const actions = {
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.LOGIN_REQUEST_SUCCESS](st) {
    st.isLoggedIn = true;
  },
  [types.LOGOUT_REQUEST_SUCCESS](st) {
    st.isLoggedIn = false;
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
