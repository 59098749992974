import HTTP from '../../../api/http-common';
import * as types from './mutation-types';

const state = {
  isRequestInProcess: false,
  error: '',
};

const actions = {
  /**
   * Стандартный выход из админки при нажатии на кнопку Выход.
   * Данный метод закрывает сессию на сервере, для этого производится запрос
   * @param commit
   * @returns {Promise.<TResult>}
   */
  logout({ commit }) {
    commit(types.LOGOUT_REQUEST);
    return new Promise((resolve, reject) => {
      HTTP.post('/auth/logout')
        .then((res) => {
          commit(types.LOGOUT_REQUEST_SUCCESS);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit(types.LOGOUT_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
  /**
   * Автоматический выход из админки если один из запросов вернул 401 ошибку (Не авторизован)
   * Аналогичен методу logout за исключением того, что сессия уже автоматически закрыта
   * @param commit
   */
  logoutWithExpiredSession({ commit }) {
    commit(types.LOGOUT_REQUEST_SUCCESS);
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  [types.LOGOUT_REQUEST](st) {
    st.isRequestInProcess = true;
  },
  [types.LOGOUT_REQUEST_SUCCESS](st) {
    window.localStorage.removeItem('token');
    st.isRequestInProcess = false;
  },
  [types.LOGOUT_REQUEST_FAILURE](st, err) {
    st.isRequestInProcess = false;
    st.error = err;
  },
};
/* eslint-enable no-param-reassign */

export default {
  state,
  actions,
  mutations,
};
