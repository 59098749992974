import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
/** Компоненты которые должны быть загружены сразу */
const Main = () => import(/* webpackChunkName: "base" */ '../components/ui/main/main.vue');
const VirtualPage = () => import(/* webpackChunkName: "base" */ '../components/ui/virtualPage/virtualPage.vue');
const Login = () => import(/* webpackChunkName: "base" */ '../components/pages/login/login.vue');
const Register = () => import(/* webpackChunkName: "base" */ '../components/pages/register/register.vue');
const RegisterSuccess = () => import(/* webpackChunkName: "base" */ '../components/pages/registerSuccess/registerSuccess.vue');
const RegisterConfirmSuccess = () => import(/* webpackChunkName: "base" */ '../components/pages/registerConfirmSuccess/registerConfirmSuccess.vue');
const RegisterConfirmError = () => import(/* webpackChunkName: "base" */ '../components/pages/registerConfirmError/registerConfirmError.vue');
const RegisterConfirmSocialError = () => import(/* webpackChunkName: "base" */ '../components/pages/registerConfirmSocialError/registerConfirmSocialError.vue');
const Forgot = () => import(/* webpackChunkName: "base" */ '../components/pages/forgot/forgot.vue');
const ForgotSuccess = () => import(/* webpackChunkName: "base" */ '../components/pages/forgotSuccess/forgotSuccess.vue');
const ForgotConfirm = () => import(/* webpackChunkName: "base" */ '../components/pages/forgotConfirm/forgotConfirm.vue');
const ForgotConfirmSuccess = () => import(/* webpackChunkName: "base" */ '../components/pages/forgotConfirmSuccess/forgotConfirmSuccess.vue');
const ForgotConfirmError = () => import(/* webpackChunkName: "base" */ '../components/pages/forgotConfirmError/forgotConfirmError.vue');
const NotFoundComponent = () => import(/* webpackChunkName: "base" */ '../components/pages/notFoundComponent/notFoundComponent.vue');

/** Компоненты админки */
const Wall = () => import(/* webpackChunkName: "adm" */ '../components/ui/wall/wall.vue');
const Wall2 = () => import(/* webpackChunkName: "adm" */ '../components/ui/wall2/wall2.vue');
const Article = () => import(/* webpackChunkName: "adm" */ '../components/ui/article/article.vue');
const Rating = () => import(/* webpackChunkName: "adm" */ '../components/ui/rating/rating.vue');
const Statisic = () => import(/* webpackChunkName: "adm" */ '../components/ui/statisic/statisic.vue');

Vue.use(Router);

const virtualPages = ['about', 'terms', 'personal-data'].map(item => ({
  path: `/${item}`,
  name: item,
  component: VirtualPage,
}));

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: Main,
    }, {
      path: '/main',
      name: 'inner-main',
      component: Main,
    }, {
      path: '/search',
      name: 'search',
      component: Wall,
    },
    {
      path: '/search/:id',
      name: 'search-detail',
      component: Article,
    },
    {
      path: '/search/:id/:cluster_id',
      name: 'search-detail',
      component: Article,
    },
    {
      path: '/search/:id/doubles',
      name: 'doubles',
      component: Wall2,
    },
    {
      path: '/search/:id/:cluster_id/doubles',
      name: 'doubles',
      component: Wall2,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    }, {
      path: '/register',
      name: 'register',
      component: Register,
    }, {
      path: '/register/success',
      name: 'register-success',
      component: RegisterSuccess,
    }, {
      path: '/register/confirm-success',
      name: 'register-confirm-success',
      component: RegisterConfirmSuccess,
    }, {
      path: '/register/confirm-error',
      name: 'register-confirm-error',
      component: RegisterConfirmError,
    }, {
      path: '/register/confirm-social-error',
      name: 'register-confirm-social-error',
      component: RegisterConfirmSocialError,
    }, {
      path: '/forgot',
      name: 'forgot',
      component: Forgot,
    }, {
      path: '/forgot/confirm',
      name: 'forgot-confirm',
      component: ForgotConfirm,
    }, {
      path: '/forgot/success',
      name: 'forgot-success',
      component: ForgotSuccess,
    }, {
      path: '/forgot/confirm-error',
      name: 'forgot-confirm-error',
      component: ForgotConfirmError,
    }, {
      path: '/forgot/confirm-success',
      name: 'forgot-confirm-success',
      component: ForgotConfirmSuccess,
    }, {
      path: '/rating',
      name: 'rating',
      component: Rating,
    }, {
      path: '/statistic',
      name: 'statistic',
      component: Statisic,
    },
    ...virtualPages,
    {
      path: '*',
      redirect: '/404',
    }, {
      path: '/404',
      component: NotFoundComponent,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { isLoggedIn } = store.state.auth;
  const userName = store.state.user.name;
  /**
   * Список name страниц для которых необходима авторизация
   */
  const arrSecuredNames = [
    'inner-main',
    'search',
    'search-detail',
  ];

  if (isLoggedIn) {
    if (userName && userName.length) {
      return next();
    }

    return next();
  }

  if (!arrSecuredNames.includes(to.name)) {
    return next();
  }

  return next('/login');
});

export default router;
