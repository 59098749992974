import * as types from './mutation-types';

const state = {
  name: '',
  avatarSrc: '',
  isAnonymous: false,
  hasComments: false,
};

const getters = {
  getUserInfo: st => st,
  getUserName: st => st.name,
  getAvatar: st => st.avatarSrc,
  isUserAnonymous: st => st.isAnonymous,
  isUserHasComments: st => st.hasComments,
};

const actions = {
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.USER_INFO_REQUEST_SUCCESS](st, data) {
    st.name = data.display_name;
    st.avatarSrc = (data.avatar && data.avatar.length) ? data.avatar : '';
    st.isAnonymous = data.is_anonymous;
    st.hasComments = data.has_comments && data.has_comments == 1;
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
