export default {
  arrErrorTranslations: {
    'login:required': 'Поле "E-mail" обязательно для заполнения',
    'login:e-mail required': 'Поле "E-mail" должно содержать e-mail',
    'password:required': 'Поле "Пароль" обязательно для заполнения',
    'password:too short': 'Длина пароля должна быть более 6 символов',
    'password:too weak': 'Пароль слишком простой',
    'status:not authorized': 'Неверное имя пользователя или пароль',
    'status:not created': 'Пользователь с данным e-mail уже существует',
    'status:email not sent': 'Не удается отправить письмо. Пожалуйста сообщите администратору',
    'status:not verified': 'Срок действия токена истек. Попробуйте запросить восстановления пароля по новой',
    'status:not activated': 'Не удалось активировать пользователя. Пожалуйста сообщите администратору',
    'status:not changed': 'Не удалось поменять пароль. Пожалуйста сообщите администратору',
  },
  getTranslation(mess) {
    return (typeof this.arrErrorTranslations[mess] !== 'undefined') ? this.arrErrorTranslations[mess] : mess;
  },
};
