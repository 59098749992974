<template>
  <div class='header-link'>
    <a
      class='header-link__link'
      :href='link'
      @click='$emit("click", $event, link)'>
        <span
          class='header-link__text'
          v-if='text'>{{text}}</span>
        <Icon
          class='header-link__icon'
          :icon='icon'
          v-if='icon'/>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon/icon.vue';
/**
 * Header сайта
 */
export default {
  name: 'HeaderLink',
  props: {
    /**
     * Имя иконки если используется
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * Текст ссылки
     */
    text: {
      type: String,
      default: '',
    },
    /**
     * Адрес ссылки
     */
    link: {
      type: String,
      default: '',
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang='scss' scoped>
$block: '.header-link';
#{$block} {
  &__link {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    text-decoration: none;
    cursor: pointer;
  }
  &__text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  &__icon {
    width: 16px;
    display: block;
    transition: transform 0.75s ease-out;
  }
  &:hover {
    #{$block}__icon {
      transform: rotate(180deg);
    }
  }
}
@media all {
  #{$block} {
    &__text {
      margin-right: 16px;
    }
  }
}
</style>
