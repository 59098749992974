<template>
  <div class='main-head'>
    <!--
    <div class='main-head__wrapper' v-if='!isUserAuthorized || (isUserAnonymous && !isInnerMain)'>
      <div class='main-head__column main-head__title-and-description'>
        <h1 class='main-head__title'>
          Поиск <br>
          по&nbsp;социальным<br> сетям
        </h1>
        <div class='main-head__description'>
          <p>Авторизуйтесь и&nbsp;ищите по&nbsp;постам<br>
          пользователей в&nbsp;популярных<br>
          социальных сетях Рунета:</p><br>
          <ul>
            <li>1.68&nbsp;млрд. публикаций</li>
            <li>130&nbsp;млн. пользователей</li>
          </ul>
        </div>
      </div>
      <div class='main-head__column'>
        <Form
          class='main-head__form'
          title='Вход'
          subtitle='Регистрация'
          subtitleLink='/register'
          :showForgotLink='true'
          :errors='login.errors'
          @submit='submitLogin'
          theme='on-color'
          v-if='form === "login"'
          :transition='false'
          @to='changeForm'
          >
          <Field
            class='form__field'
            placeholder='E-mail'
            name='email'
            type='text'
            theme='on-color'
            :error='login.err.email'
            v-model='login.email'
            @click='clearFieldError("login", "email")'/>
          <Field
            class='form__field'
            placeholder='Пароль'
            name='password'
            type='password'
            theme='on-color'
            :error='login.err.password'
            v-model='login.password'
            @click='clearFieldError("login", "password")'/>
        </Form>
        <Form
          class='reg main-head__form'
          title='Регистрация'
          subtitle='Вход'
          subtitleLink='/login'
          submitText='Зарегистрироваться'
          :errors='reg.errors'
          @submit='submitReg'
          theme='on-color'
          :transition='false'
          :showSocial='false'
          @to='changeForm'
          v-else>
          <Field
            class='form__field'
            placeholder='E-mail'
            name='email'
            type='text'
            theme='on-color'
            :error='reg.err.email'
            v-model='reg.email'
            @click='clearFieldError("reg", "email")'/>
          <Field
            class='form__field'
            placeholder='Пароль'
            name='password'
            type='password'
            theme='on-color'
            :error='reg.err.password'
            v-model='reg.password'
            @click='clearFieldError("reg", "password")'/>
          <Field
            class='form__field'
            placeholder='Повторите пароль'
            name='confirm_password'
            type='password'
            theme='on-color'
            :error='reg.err.password2'
            v-model='reg.password2'
            @click='clearFieldError("reg", "password2")'/>
        </Form>
      </div>
    </div>
    -->
    <div
      :class='{
        "main-head__inner": true,
        "main-head__inner--first": isFirstLogin,
      }'>
      <div class='seach main-head-search'>
        <h1 class='search__title' v-if='!isFirstLogin'>Поиск по&nbsp;социальным сетям</h1>
        <SearchForm class='search__form' :disableScroll='isFirstLogin'/>
        <div class='main-head-search__tiles' v-if='isFirstLogin'>
          <div class='tile tile--one'>
            <p class='tile__text'>Ищите по&nbsp;ключевым<br> словам или фразам</p>
          </div>
          <div class='tile tile--two'>
            <p class='tile__text'>Вы&nbsp;можете указать имя<br> автора или название<br> сообщества</p>
          </div>
          <div class='tile tile--three'>
            <p class='tile__text'>Выбирайте платформу<br> или ищите по&nbsp;всем<br> социальным сетям сразу</p>
          </div>
          <div class='tile tile--four'>
            <p class='tile__text'>Вы&nbsp;всегда можете задать<br> точные дату и&nbsp;время<br> для вашего поиска</p>
          </div>
          <div class='tile__close close' @click='toggleHint'>
            <div class='close__wrapper'>
              <span class='close__text'>Больше не показывать</span>
              <Icon class='close__icon' icon='close'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recalcGradient } from '@/api/utils';
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon/icon.vue';
import SearchForm from '@/components/ui/searchForm/searchForm.vue';
/**
 * Компонент шапки главной страницы сайта
 */
export default {
  name: 'MainHead',
  components: {
    Icon,
    SearchForm,
  },
  computed: {
    ...mapGetters([
      'isUserAuthorized',
      'getUserName',
      'getPrevPath',
      'isUserAnonymous',
    ]),
    isInnerMain() {
      return this.$route.name === 'inner-main';
    },
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      isFirstLogin: !localStorage.getItem('visited'),
      login: {
        email: '',
        password: '',
        errors: '',
        err: {
          email: false,
          password: false,
        },
      },
      reg: {
        email: '',
        password: '',
        password2: '',
        errors: '',
        err: {
          email: false,
          password: false,
          password2: false,
        },
      },
      form: 'login',
      errors: {
        emptyLogin: 'Поле "E-mail" обязательно для заполнения',
        emailLogin: 'Поле "E-mail" должно содержать e-mail',
        emptyPassword: 'Поле "Пароль" обязательно для заполнения',
        emptyPasswordMinHeight: 'Длина пароля должна быть не менее 6 символов',
        emptyPasswordConfirm: 'Поле "Подтвердите пароль" обязательно для заполнения',
        passwordNotEqual: 'Введенные пароли не совпадают',
      },
    };
  },
  methods: {
    clearFieldError(form, field) {
      this[form].err[field] = false;
    },
    clearErrors(form = '') {
      if (!form) {
        return false;
      }

      this[form].errors = '';
    },
    submitLogin() {
      let err = '';
      const isEmailExist = this.login.email && this.login.email.length;
      /* eslint-disable-next-line */
      const isEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.login.email);
      const isPasswordExist = this.login.password && this.login.password.length;
      if (!isEmailExist) {
        err += `${this.errors.emptyLogin}<br>`;
        this.login.err.email = true;
      }
      if (!isPasswordExist) {
        err += `${this.errors.emptyPassword}<br>`;
        this.login.err.password = true;
      }
      if (this.login.password.length < 6 && isPasswordExist) {
        err += `${this.errors.emptyPasswordMinHeight}<br>`;
        this.login.err.password = true;
      }

      if (!isEmail && isEmailExist) {
        err += `${this.errors.emailLogin}<br>`;
        this.login.err.email = true;
      }

      if (err) {
        this.login.errors = err;
        return false;
      }

      return this.$store.dispatch('login', {
        login: this.login.email,
        password: this.login.password,
      })
        .then(() => {
          this.$store.dispatch('userInfo');
          this.clearErrors('login');
          const toRoute = this.getPrevPath && this.getPrevPath.length > 1 ? this.getPrevPath : '/main';
          this.$router.push(toRoute);
        })
        .catch((erro) => {
          this.login.errors = erro;
          this.login.err.password = true;
          recalcGradient();
        });
    },
    changeForm() {
      this.form = (this.form === 'login') ? 'register' : 'login';
      recalcGradient();
    },
    submitReg() {
      let err = '';
      const isEmailExist = this.reg.email && this.reg.email.length;
      /* eslint-disable-next-line */
      const isEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.reg.email);

      const isPasswordExist = this.reg.password && this.reg.password.length;
      const isConfirmPasswordExist = this.reg.password2 && this.reg.password2.length;

      if (!isEmailExist) {
        err += `${this.errors.emptyLogin}<br>`;
        this.reg.err.email = true;
      }
      if (!isEmail && isEmailExist) {
        err += `${this.errors.emailLogin}<br>`;
        this.reg.err.email = true;
      }

      if (!isPasswordExist) {
        err += `${this.errors.emptyPassword}<br>`;
        this.reg.err.password = true;
      }
      if (!isConfirmPasswordExist) {
        err += `${this.errors.emptyPasswordConfirm}`;
        this.reg.err.password2 = true;
      }
      if (
        (this.reg.password.length < 6 && isPasswordExist) ||
        (this.reg.password2.length < 6 && isConfirmPasswordExist)
      ) {
        err += `${this.errors.emptyPasswordMinHeight}<br>`;
        this.reg.err.password2 = true;
      }

      if (this.reg.password !== this.reg.password2) {
        err += `${this.errors.passwordNotEqual}<br>`;
        this.reg.err.password2 = true;
      }

      if (err) {
        this.reg.errors = err;
        return false;
      }

      return this.$store.dispatch('register', {
        login: this.reg.email,
        password: this.reg.password,
      })
        .then(() => {
          this.$router.push('/register/success');
          this.clearErrors('reg');
        })
        .catch((erro) => {
          this.reg.errors = erro;
          this.reg.err.password2 = true;
          recalcGradient();
        });
    },
    init() {
      const errorElem = document.querySelector('.form__errors');
      if (!errorElem) {
        return false;
      }
      const events = ['transitionend', 'webkitTransitionEnd', 'oTransitionEnd', 'otransitionend', 'MSTransitionEnd'];
      for (const event of events) {
        errorElem.addEventListener(event, () => {
          recalcGradient();
        }, false);
      }
    },
    toggleHint() {
      localStorage.setItem('visited', true);
      this.isFirstLogin = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import './mainHead.scss';

.search {
  z-index: 20;
  &__title {
    color: rgba(#ffffff, 0.3);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
}

.main-head-search {
  position: relative;
  &__tiles {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.tile {
  position: absolute;
  width: 30%;
  padding: 0 16px;
  &__text {
    color: #aab1b7;
  }
  &--one {
    bottom: calc(100% + 64px);
    left: 0;
    &:before {
      width: 48px;
      height: 96px;

      position: absolute;
      left: -48px;
      top: calc(100% - 20px);

      border: 1px solid #a2acb3;
      border-right: 0;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;;
      background-color: transparent;
      transform: rotate(10deg);
      content: ' ';
    }
  }
  &--two {
    top: calc(100% + 64px);
    left: 0;
    &:before {
      width: 48px;
      height: 96px;

      position: absolute;
      left: -48px;
      bottom: calc(100% - 20px);

      border: 1px solid #a2acb3;
      border-right: 0;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;;
      background-color: transparent;
      transform: rotate(-10deg);
      content: ' ';
    }
  }
  &--three {
    top: calc(100% + 88px);
    left: 35%;
    &:before {
      width: 1px;
      height: 72px;
      position: absolute;
      left: calc(50% + 1px);
      bottom: calc(100% + 8px);
      background-color: #a2acb3;
      content: ' ';
    }
  }
  &--four {
    top: calc(100% + 64px);
    right: 0;
    &:before {
      width: 48px;
      height: 96px;

      position: absolute;
      right: -48px;
      bottom: calc(100% - 20px);

      border: 1px solid #a2acb3;
      border-left: 0;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      background-color: transparent;
      transform: rotate(10deg);
      content: ' ';
    }
  }
  &__close {
    position: absolute;
    bottom: calc(100% + 64px);
    right: 0;
    z-index: 20;
  }
}

@media all {
  .search {
    &__title {
      margin-bottom: 16px;
    }
  }
}

@media all and (min-width: 640px) {
  .search {
    &__title {
      margin-bottom: 60px;

      font-size: 42px;
      line-height: 46px;
    }
    &__field {
      margin-right: 20px;
    }
  }
}
</style>
<style lang='scss' scoped>
.close {
  cursor: pointer;
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
  }
  &__icon {
    margin-left: 16px;
    fill: #ffffff;
  }
}
</style>
