<template>
    <IconShares v-if='iconSrc && iconSrc === "shares"'/>
    <IconDuplicates v-else-if='iconSrc && iconSrc === "duplicates"'/>
    <IconComments v-else-if='iconSrc && iconSrc === "comments"'/>
    <IconLikes v-else-if='iconSrc && iconSrc === "likes"'/>
    <IconImages v-else-if='iconSrc && iconSrc === "images"'/>

    <IconVkontakte v-else-if='iconSrc && iconSrc === "vkontakte"'/>
    <IconVkontakteColor v-else-if='iconSrc && iconSrc === "vkontakte-color"'/>
    <IconOdnoklassnikiColor v-else-if='iconSrc && iconSrc === "odnoklassniki-color"'/>
    <IconTwitter v-else-if='iconSrc && iconSrc === "twitter"'/>
    <IconTwitterColor v-else-if='iconSrc && iconSrc === "twitter-color"'/>
    <IconFacebook v-else-if='iconSrc && iconSrc === "facebook"'/>
    <IconFacebookColor v-else-if='iconSrc && iconSrc === "facebook-color"'/>
    <IconInstagramColor v-else-if='iconSrc && iconSrc === "instagram-color"'/>
    <IconYoutubeColor v-else-if='iconSrc && iconSrc === "youtube-color"'/>
    <IconLivejournalColor v-else-if='iconSrc && iconSrc === "livejournal-color"'/>
    <IconMailRuColor v-else-if='iconSrc && iconSrc === "mailru-color"'/>
    <IconTelegram v-else-if='iconSrc && iconSrc === "telegram"'/>
    <IconTelegramColor v-else-if='iconSrc && iconSrc === "telegram-color"'/>
    <IconYandex v-else-if='iconSrc && iconSrc === "yandex"'/>

    <IconArrowRight v-else-if='iconSrc && iconSrc === "arrow-right"'/>
    <IconArrowTop v-else-if='iconSrc && iconSrc === "arrow-top"'/>
    <IconArrowUp v-else-if='iconSrc && iconSrc === "arrow-up"'/>
    <IconArrowDown v-else-if='iconSrc && iconSrc === "arrow-down"'/>

    <IconLink v-else-if='iconSrc && iconSrc === "link"'/>
    <IconDefault v-else-if='iconSrc && iconSrc === "default"'/>
    <IconClock v-else-if='iconSrc && iconSrc === "clock"'/>
    <IconClose v-else-if='iconSrc && iconSrc === "close"'/>
    <IconEye v-else-if='iconSrc && iconSrc === "eye"'/>
    <IconExclamation v-else-if='iconSrc && iconSrc === "exclamation"'/>

    <IconLogin v-else-if='iconSrc && iconSrc === "login"'/>
    <IconLogout v-else-if='iconSrc && iconSrc === "logout"'/>
    <IconLogo v-else-if='iconSrc && iconSrc === "logo"'/>
    <IconLogoNew v-else-if='iconSrc && iconSrc === "logo-new"'/>
    <IconLogoMobile v-else-if='iconSrc && iconSrc === "logo-mobile"'/>
    <IconMenu v-else-if='iconSrc && iconSrc === "menu"'/>
    <IconMenuClose v-else-if='iconSrc && iconSrc === "menu-close"'/>
    <IconExplorer v-else-if='iconSrc && iconSrc === "explorer"'/>
</template>

<script>
import { isExist } from '@/api/utils';
/**
 * Иконки для статей
 */
import IconShares from '@/img/svg/icon-shares.svg';
import IconDuplicates from '@/img/svg/icon-duplicates.svg';
import IconComments from '@/img/svg/icon-comments.svg';
import IconLikes from '@/img/svg/icon-likes.svg';
import IconImages from '@/img/svg/icon-images.svg';

/**
 * Иконки соцсетей цветные
 */
import IconVkontakte from '@/img/svg/icon-vkontakte.svg';
import IconVkontakteColor from '@/img/svg/icon-vkontakte-color.svg';
import IconOdnoklassnikiColor from '@/img/svg/icon-odnoklassniki-color.svg';
import IconTwitter from '@/img/svg/icon-twitter.svg';
import IconTwitterColor from '@/img/svg/icon-twitter-color.svg';
import IconFacebook from '@/img/svg/icon-facebook.svg';
import IconFacebookColor from '@/img/svg/icon-facebook-color.svg';
import IconInstagramColor from '@/img/svg/icon-instagram-color.svg';
import IconYoutubeColor from '@/img/svg/icon-youtube-color.svg';
import IconLivejournalColor from '@/img/svg/icon-livejournal-color.svg';
import IconMailRuColor from '@/img/svg/icon-mailru-color.svg';
import IconTelegram from '@/img/svg/icon-telegram.svg';
import IconTelegramColor from '@/img/svg/icon-telegram-color.svg';
import IconYandex from '@/img/svg/icon-yandex.svg';

/**
 * Остальные
 */
import IconArrowRight from '@/img/svg/icon-arrow-right.svg';
import IconArrowTop from '@/img/svg/icon-arrow-top.svg';
import IconArrowUp from '@/img/svg/icon-arrow-up.svg';
import IconArrowDown from '@/img/svg/icon-arrow-down.svg';

import IconLink from '@/img/svg/icon-link.svg';
import IconDefault from '@/img/svg/icon-default.svg';
import IconClock from '@/img/svg/icon-clock.svg';
import IconClose from '@/img/svg/icon-close.svg';
import IconEye from '@/img/svg/icon-eye.svg';
import IconExclamation from '@/img/svg/icon-exclamation.svg';

import IconLogin from '@/img/svg/icon-login.svg';
import IconLogout from '@/img/svg/icon-logout.svg';
import IconLogo from '@/img/svg/icon-logo.svg';
import IconLogoMobile from '@/img/svg/icon-logo-mobile.svg';
import IconMenu from '@/img/svg/icon-menu.svg';
import IconMenuClose from '@/img/svg/icon-menu-close.svg';
import IconExplorer from '@/img/svg/icon-explorer.svg';
import IconLogoNew from '@/img/svg/icon-logo-new.svg';

/**
 * Компонент отображающий материал
 */
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
    },
  },
  components: {
    IconShares,
    IconDuplicates,
    IconComments,
    IconLikes,
    IconImages,
    IconVkontakte,
    IconVkontakteColor,
    IconOdnoklassnikiColor,
    IconTwitter,
    IconTwitterColor,
    IconFacebook,
    IconFacebookColor,
    IconInstagramColor,
    IconYoutubeColor,
    IconLivejournalColor,
    IconMailRuColor,
    IconTelegram,
    IconTelegramColor,
    IconYandex,
    IconArrowRight,
    IconArrowTop,
    IconArrowUp,
    IconArrowDown,
    IconLink,
    IconDefault,
    IconClock,
    IconClose,
    IconEye,
    IconExclamation,
    IconLogin,
    IconLogout,
    IconLogo,
    IconLogoMobile,
    IconMenu,
    IconMenuClose,
    IconExplorer,
    IconLogoNew,
  },
  computed: {
    iconSrc() {
      if (!isExist(this, 'icon')) {
        return '';
      }
      return this.icon;
    },
  },
};
</script>
<style lang='scss' scoped>
$block: '.icon';

#{$block} {

}

@media all and (min-width: 960px) {
  #{$block} {

  }
}
</style>
