import qs from 'qs';
import HTTP from '../../../api/http-common';
import * as types from './mutation-types';

const state = {
  isRequestInProcess: false,
  error: '',
};

const actions = {
  /**
   * Вход
   * @param commit
   * @param payload
     */
  login({ commit }, data) {
    commit(types.LOGIN_REQUEST);
    return new Promise((resolve, reject) => {
      HTTP.post('/auth/login', qs.stringify(data))
        .then((res) => {
          commit(types.LOGIN_REQUEST_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          commit(types.LOGIN_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  [types.LOGIN_REQUEST](st) {
    st.isRequestInProcess = true;
  },
  [types.LOGIN_REQUEST_SUCCESS](st) {
    st.isRequestInProcess = false;
    st.error = '';
  },
  [types.LOGIN_REQUEST_FAILURE](st, err) {
    st.isRequestInProcess = false;
    st.error = err;
  },
};
/* eslint-enable no-param-reassign */

export default {
  state,
  actions,
  mutations,
};
