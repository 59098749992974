<template>
  <form class='search' action='/' method='get' @submit.prevent='submit' ref='search'>
    <div
      :class='{
        "search__row": true,
        "search__row--marginBottom0": !isOpen && !disableScroll,
      }'>
      <div class='search__field'>
        <input
          class='field field--query'
          id='query'
          placeholder='Поисковый запрос'
          v-model='query'>
        <a
          class='search__field-close'
          @click='query = "";'>
          <Icon icon='close' v-if='query && query.length > 0'/>
        </a>
      </div>
      <Button
        class='search__button search__submit'
        text='Найти'
        type='submit'
        :showText='!isTextVisible'/>
      <a
        :class='{
          "search__link": true,
          "search__link--is-open": !isOpen,
        }'
        v-if='!disableScroll && !isOpen'
        @click='toggleMenu'>{{text}}<Icon class='search__link-icon' icon='arrow-top'/></a>
    </div>
      <div
        :class='{
          "search__row": true,
          "search__row--hidden": !isOpen && !disableScroll,
          "js-scroll": true,
        }'>
        <multiselect
          class='select'
          placeholder='Автор: все'
          v-model='select.author'
          :showLabels='false'
          :showPointer='false'
          :options='authors'
          :internal-search='false'
          :loading='isAuthorLoad'
          :taggable='true'
          :allow-empty='false'
          @search-change='searchAuthorChange'
          @tag='onAddAuthor'>
          <template
            slot='singleLabel'
            slot-scope='props'>
              <span>Автор: {{ props.option.label }}</span>
          </template>
          <template
            slot='noResult'
          >
              <span>Автор не найден</span>
          </template>
          <template
            slot='option'
            slot-scope='props'>
              <div class='item'>
                <Icon class='item__icon social__logo' :icon='props.option.social.code' v-if='!!props.option.social && !!props.option.social.code'/>
                <span class='item__label'>{{ props.option.label }}</span>
              </div>
          </template>
          <template
            slot='clear'
            slot-scope='props'
            v-if='platform && platform.value'>
            <div
              class='multiselect__clear'
              @mousedown.prevent.stop='clearPlatform(props.search)'></div>
          </template>
        </multiselect>
        <multiselect
          class='select'
          placeholder='Платформа: все'
          v-model='select.platform'
          :showLabels='false'
          :showPointer='false'
          :options='platforms'
          :internal-search='false'
          :loading='isPlatformLoad'
          :taggable='true'
          :allow-empty='false'
          @search-change='searchChange'
          @tag='onAddTag'>
          <template
            slot='singleLabel'
            slot-scope='props'>
              <span>Платформа: {{ props.option.label }}</span>
          </template>
          <template
            slot='noResult'
          >
              <span>Платформа не найдена</span>
          </template>
          <template
            slot='option'
            slot-scope='props'>
              <div class='item'>
                <Icon class='item__icon social__logo' :icon='props.option.icon'/>
                <span class='item__label'>{{ props.option.label }}</span>
              </div>
          </template>
          <template
            slot='clear'
            slot-scope='props'
            v-if='platform && platform.value'>
            <div
              class='multiselect__clear'
              @mousedown.prevent.stop='clearPlatform(props.search)'></div>
          </template>
        </multiselect>
        <multiselect
          class='select'
          placeholder='Период: Неделя'
          v-model='select.time'
          :options='periods'
          :searchable='false'
          :showPointer='false'
          :showLabels='false'
          :allow-empty='false'>
          <template
            slot='singleLabel'
            slot-scope='props'>
              <span>Период: {{ props.option.label }}</span>
          </template>
          <template
            slot='option'
            slot-scope='props'>
              <div class='item'>
                <span class='item__label'>{{ props.option.label }}</span>
              </div>
          </template>
        </multiselect>
        <a
        :class='{
          "search__link": true,
          "search__link--is-open": !isOpen,
        }'
        v-if='!disableScroll && isOpen'
        @click='toggleMenu'>{{text}}<Icon class='search__link-icon' icon='arrow-top'/></a>
      </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Button from '@/components/ui/button/button.vue';
import Icon from '@/components/ui/icon/icon.vue';
import Multiselect from 'vue-multiselect';
import './vue-multiselect.min.css';

/**
 * Компонент формы поиска
 */
export default {
  name: 'SearchForm',
  components: {
    Button,
    Multiselect,
    Icon,
  },
  props: {
    disableScroll: {
      type: Boolean,
      default: false,
    },
    isFormOpen: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.onResize();
    const events = ['resize', 'scroll'];
    for (const event of events) {
      const handlerName = `on${event[0].toUpperCase() + event.slice(1)}`;
      this.listeners[event] = window.addEventListener(event, this[handlerName]);
    }

    /**
     * Восстанавливаем дефолтные значения из стора
     */
    const resA = this.authors.find(item => item.value === this.author);
    const resT = this.periods.find(item => item.value === this.time);
    const resP = this.platforms.find(item => item.value === this.platform);
    this.select.author = resA || this.authors[0];
    this.select.time = resT || this.periods[0];
    this.select.platform = resP || this.platforms[0];

    /**
     * Пытаемся восстановить значения из роута
     */
    this.restoreParamsFromRoute(this.$route.query);

    let title = 'Крибрум: результаты поиска';
    if (this.query && this.query.length > 0) {
      title = `${this.query} - ${title}`;
    }
    document.title = title;
  },
  mounted() {
    this.$nextTick(function () {
      this.$store.dispatch('search');
    });
  },
  destroyed() {
    this.listeners.map((item) => {
      window.removeEventListener(item, this.listeners[item]);
    });
    this.listeners = [];
  },
  watch: {
    $route() {
      this.$store.commit('SET_PREV_PATH', this.$route.fullPath);

      this.restoreParamsFromRoute(this.$route.query);
      this.$store.dispatch('search');
    },
    'select.author': function (e) {
      const value = !e || !e.value ? '' : e.value;
      this.$store.commit('UPDATE_PARAM', { field: 'author', value });
      if (!!e && !!e.social && !!e.social.raw) {
        const platform = this.platforms.find(obPlatform => obPlatform.value === e.social.raw);
        if (platform) {
          this.select.platform = platform;
        } else {
          const newPlatform = {
            label: e.social.raw,
            value: e.social.raw,
            icon: 'explorer',
          };
          this.$store.commit('SEARCH_PLATFORM_ADD', newPlatform);
          this.select.platform = newPlatform;
        }
      }
    },
    'select.platform': function (e) {
      const value = !e || !e.value ? '' : e.value;
      this.$store.commit('UPDATE_PARAM', { field: 'platform', value });
    },
    'select.time': function (e) {
      const value = !e || !e.value ? '' : e.value;
      this.$store.commit('UPDATE_PARAM', { field: 'time', value });
    },
    isFormOpen(value) {
      this.isOpen = value;
      this.text = value ? 'Свернуть' : 'Развернуть';
    },
  },
  computed: {
    query: {
      get() { return this.$store.state.search.curQuery.query; },
      set(e) { return this.$store.commit('UPDATE_PARAM', { field: 'query', value: e }); },
    },
    author: {
      get() { return this.$store.state.search.curQuery.author; },
      set(e) { return this.$store.commit('UPDATE_PARAM', { field: 'author', value: e }); },
    },
    order: {
      get() { return this.$store.state.search.curQuery.order; },
      set(e) { return this.$store.commit('UPDATE_PARAM', { field: 'order', value: e }); },
    },
    platform: {
      get() { return this.$store.state.search.curQuery.platform; },
      set(e) { return this.$store.commit('UPDATE_PARAM', { field: 'platform', value: e }); },
    },
    time: {
      get() { return this.$store.state.search.curQuery.time; },
      set(e) { return this.$store.commit('UPDATE_PARAM', { field: 'time', value: e }); },
    },
    ...mapState({
      isAuthorLoad: state => state.author.isLoading,
      isPlatformLoad: state => state.platform.isLoading,
    }),
    ...mapGetters({
      authors: 'getAuthors',
      platforms: 'getPlatforms',
      periods: 'getPeriods',
    }),
  },
  data() {
    return {
      isOpen: this.isFormOpen,
      isMobile: true,
      isTextVisible: false,
      listeners: [],
      text: 'Свернуть',
      timer: '',
      select: {
        author: '',
        platform: '',
        time: '',
      },
    };
  },
  methods: {
    ...mapActions([
      'search',
    ]),
    restoreParamsFromRoute(obQueries) {
      Object.keys(obQueries).map((item) => {
        switch (item) {
          case 'query':
          case 'order':
            this[item] = obQueries[item];
            break;
          case 'author': {
            const resA = this.authors.find(obAuthor => obAuthor.value === obQueries[item]);
            if (resA) {
              this.select[item] = resA;
            } else {
              const newAuthor = {
                label: obQueries[item],
                value: obQueries[item],
              };
              this.$store.commit('AUTHOR_ADD', newAuthor);
              this.select[item] = newAuthor;
            }
            break;
          }
          case 'platform': {
            const resP = this.platforms.find(obPlatform => obPlatform.value === obQueries[item]);
            if (resP) {
              this.select[item] = resP;
            } else {
              const newPlatform = {
                label: obQueries[item],
                value: obQueries[item],
                icon: 'explorer',
              };
              this.$store.commit('SEARCH_PLATFORM_ADD', newPlatform);
              this.select[item] = newPlatform;
            }
            break;
          }
          case 'time': {
            if (!obQueries[item].length) {
              break;
            }
            const resT = this.periods.find(obPeriod => obPeriod.value == obQueries[item]);
            this.select[item] = resT || this.periods[0];
            break;
          }
          default:
            this[item] = obQueries[item];
            break;
        }
      });
    },
    submit() {
      this.$emit('click');
      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: this.time,
          order: this.order,
        },
      });
      if (window.scrollY > 0) {
        window.scrollTo(0, 0);
      }
      this.$store.dispatch('search', true);
    },
    toggleMenu() {
      if (this.isMobile) {
        return;
      }
      this.isOpen = !this.isOpen;
      this.text = this.isOpen ? 'Свернуть' : 'Развернуть';
    },
    onResize() {
      this.isMobile = window.outerWidth < 960;
      this.isTextVisible = window.outerWidth < 640;
    },
    onScroll() {
      if (this.disableScroll) {
        return false;
      }
      const scrollFromTop = window.scrollY;
      return ((scrollFromTop > 0 && this.isOpen) || (scrollFromTop === 0 && !this.isOpen)) ? this.toggleMenu() : '';
    },
    searchChange(text) {
      this.$store.dispatch('searchPlatform', text);
    },
    searchAuthorChange(text) {
      this.$store.dispatch('searchAuthor', text);
    },
    clearPlatform() {
      this.platform = '';
    },
    onAddTag(selectedOption) {
      const newItem = {
        label: selectedOption,
        value: selectedOption,
        icon: 'explorer',
      };
      this.$store.dispatch('addPlatform', newItem);
      this.$nextTick(function () {
        this.select.platform = newItem;
        this.$nextTick(() => this.submit());
      });
    },
    onAddAuthor(selectedOption) {
      const newItem = {
        label: selectedOption,
        value: selectedOption,
      };
      this.$store.dispatch('addAuthor', newItem);
      this.$nextTick(function () {
        this.select.author = newItem;
        this.$nextTick(() => this.submit());
      });
    },
  },
};
</script>

<style scoped lang="scss">
  $block: '.search';

  #{$block} {
    position: relative;
    &__row {
      margin-bottom: 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.4s ease;
      will-change: auto;
      max-height: 500px;
      opacity: 1;
      position: relative;
      z-index: 11;
      &:last-of-type {
        margin-bottom: 0;
      }
      &--hidden {
        max-height: 0;
        transform: translateY(-32px);
        opacity: 0;
        z-index: 9;
      }
      &--marginBottom0 {
        margin-bottom: 0;
      }
    }
    &__field {
      flex: 1 1 auto;
      &:-webkit-autofill {
          -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
          -webkit-text-fill-color: #1a1a1a !important;
          color: #1a1a1a !important;
      }
    }
    &__link {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      position: absolute;
      left: calc(100% + 16px);
      top: 5px;

      display: none;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s linear;
      &-icon {
        width: 12px;
        margin-left: 8px;
        transform: rotate(0);
      }
      &--is-open {
        .search__link-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  .select {
    width: 30%;
    flex: 0 0 auto;

    color: #1A1A1A;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    border-radius: 5px;
    background-color: #ffffff;
    input {
      padding: 10px 20px;
      height: auto !important;
    }
  }
  .field {
    padding: 7px 20px;
    color: #1a1a1a;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 18px;
    border: 0;
    border-radius: 5px;
    background: #fff;
    outline: none;
  }
  .item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    &__icon {
      width: 20px;
      margin-right: 20px;
    }
  }


  .search__field,
  .author_field {
    position: relative;
  }
  .search__field .field,
  .author_field .field {
    width: 100%;
  }
  .search__field-close,
  .author__field-close {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.1;
    cursor: pointer;
    transition: opacity .1s ease-out;
    &:hover {
      opacity: 0.8;
    }
  }

  @media all {
    #{$block} {

    }
  }

  @media all and (max-width: 639px) {
    #{$block} {
      &__button {
        width: 32px !important;
        height: 32px;
        min-width: auto;
        font-size: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background-image: url('/img/svg/icon-search.svg');
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position: center;
      }
      &__row {
        flex-flow: row wrap;
      }
      &__field {
        flex: 0 0 calc(100% - 32px);
      }
    }
    .author_field {
      flex: 0 0 100%;
    }
    .select {
      flex: 0 0 100%;
      margin-top: 10px;
    }
    .field {
      &--query {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  @media all and (min-width: 640px) {
    #{$block} {
      &__field {
        margin-right: 20px;
      }
    }
  }

  @media all and (min-width: 960px) {
    #{$block} {
      &__link {
        display: flex;
      }
    }
    .author_field {
      width: 30%;
    }
  }
</style>
