<template>
  <main class='body'>
    <slot/>
  </main>
</template>

<script>
/**
 * Контентная часть сайта
 */
export default {
  name: 'Body',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
$block: '.body';
</style>
