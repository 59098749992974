<template>
  <div
    :class='{
      page: true,
      "page--404": is404
    }'
  >
    <Header
      :class='{
        "page__header": true,
        "header--transparent": is404,
      }'
    />
    <MainHead
      :class='{
        "marginHead": isMain,
      }'
      v-if='isMain'/>
    <Body
      :class='{
        "page__body": true,
        "wrapper": !isStat,
        "marginHead": !isMain,
        "marginHead--search": !isMain && isSearch,
      }'>
      <slot/>
    </Body>
    <Footer
      :class='{
        "page__footer": true,
        "footer--transparent": is404
      }'
    />
  </div>
</template>

<script>
import Header from '@/components/new/header/header.vue';
import Body from '@/components/other/body/body.vue';
import Footer from '@/components/new/footer/footer.vue';
import MainHead from '@/components/ui/mainHead/mainHead.vue';
/**
 * Page сайта
 */
export default {
  name: 'Page',
  components: {
    Header,
    MainHead,
    Body,
    Footer,
  },
  computed: {
    is404() {
      return this.$route.path === '/404';
    },
    isMain() {
      return this.$route.name === 'main' || this.$route.name === 'inner-main';
    },
    isSearch() {
      return this.$route.name === 'search' || this.$route.name === 'doubles';
    },
    isStat() {
      return this.$route.name === 'statistic';
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  &__header,
  &__footer {
    flex: 0 0 auto;
  }
  &__body {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  &--404 {
    background-image: linear-gradient(150deg, #5a7380, #475a67 11%, #33404d 24%, #2c3643 33%, #5a7380);
    background-image: url('../../../img/general/bg404@3x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media all {
  .marginHead {
    margin-top: 60px;
    &--search {
      margin-top: 100px;
    }
  }
}

@media all and (min-width: 960px) {
  .marginHead {
    &--search {
      margin-top: 142px;
    }
  }
}
</style>
