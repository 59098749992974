/** Authorization */
/** Login */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

/** Logout */
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAILURE = 'LOGOUT_REQUEST_FAILURE';

/** Forgot */
export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_REQUEST_SUCCESS = 'FORGOT_REQUEST_SUCCESS';
export const FORGOT_REQUEST_FAILURE = 'FORGOT_REQUEST_FAILURE';

/** Register */
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';

/** Change password */
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'CHANGE_PASSWORD_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_REQUEST_FAILURE = 'CHANGE_PASSWORD_REQUEST_FAILURE';
