import HTTP from '../../../api/http-common';
import * as types from './mutation-types';
import * as authTypes from '../auth/mutation-types';

const state = {
  isRequestInProcess: false,
  error: '',
};

const actions = {
  /**
   * Вход
   * @param commit
   * @param payload
     */
  userInfo({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.USER_INFO_REQUEST);
      HTTP.get('user/info')
        .then((res) => {
          commit(types.USER_INFO_REQUEST_SUCCESS, res.data);
          if (res.data.auth === 1) {
            commit(authTypes.LOGIN_REQUEST_SUCCESS);
          }
          resolve(res.data);
        })
        .catch((err) => {
          commit(types.USER_INFO_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  [types.USER_INFO_REQUEST](st) {
    st.isRequestInProcess = true;
  },
  [types.USER_INFO_REQUEST_SUCCESS](st) {
    st.isRequestInProcess = false;
    st.error = '';
  },
  [types.USER_INFO_REQUEST_FAILURE](st, err) {
    st.isRequestInProcess = false;
    st.error = err;
  },
};
/* eslint-enable no-param-reassign */

export default {
  state,
  actions,
  mutations,
};
