<template>
  <div id="app">
    <Page>
      <router-view class='child-view'/>
    </Page>
    <portal-target name='mobile'></portal-target>
  </div>
</template>
<script>
import Page from '@/components/other/page/page.vue';

export default {
  name: 'App',
  components: {
    Page,
  },
};
</script>
<style lang='scss'>
@import './config/common.scss';

.popup {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;

  border-top: 4px solid #5a7380;
  background-color: rgba(#000000, 0.6);
  background-image: linear-gradient(150deg, #5a7380, #475a67 11%, #33404d 24%, #2c3643 33%, #5a7380);

  &--autoSize {
    height: auto;
  }
  &__wrapper {
    padding: 8px 16px;
  }
}
</style>
