<template>
  <div class='profile'>
    <div class='profile__wrapper'>
        <img
          class='profile__avatar'
          v-if='avatarSrc && avatarSrc.length'
          :src='avatarSrc'/>
        <Icon
          class='profile__avatar'
          icon='default'
          v-else/>
        <span class='profile__name'>{{computedName}}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon/icon';
/**
 * Профиль
 */
export default {
  name: 'Profile',
  props: {
    /**
     * Имя человека или его логин
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * Путь к аватарке
     */
    avatarSrc: {
      type: String,
      default: '',
    },
    /**
     * Максимальная длина имени в символах
     */
    nameLength: {
      type: Number,
      default: 10,
    },
  },
  components: {
    Icon,
  },
  computed: {
    computedName() {
      const name = this.name;
      if (!name || !name.length) {
        return '';
      }
      return name.length > 10 ? `${name.substr(0, 10)}...` : name;
    },
  },
};
</script>

<style lang='scss' scoped>
$block: '.profile';

#{$block} {
  &__wrapper {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__name {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-decoration: none;
  }
  &__avatar {
    width: 35px;
    height: 35px;
    border-radius: 18px;
  }
}

@media all {
  #{$block} {
    &__name {
      //margin-right: 50px
    }
    &__avatar {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      border-radius: 16px;
    }
  }
}
</style>
