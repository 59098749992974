import axios from 'axios';
import translate from '@/api/translate';
import store from '@/store';
import router from '@/router';

const HTTP = axios.create({
  baseURL: '/api/v1/',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
});
/**
 * Данный интрцептор используется для
 * 1) Замены всех сообщений на их русские аналоги
 * 2) Автоматический выход из админки при получении 401 ошибки
 */
HTTP.interceptors.response.use((response) => {
  if (response.status !== 200 || (Object.hasOwnProperty.call(response.data, 'error') && response.data.error !== null)) {
    const mess = Object.keys(response.data.error).reduce((prev, fieldName) => {
      const msg = response.data.error[fieldName];
      const messageForTranslation = `${fieldName}:${msg}`;
      return `${prev}${translate.getTranslation(messageForTranslation)}<br>`;
    }, '');
    throw new Error(mess);
  }
  return response;
}, (error) => {
  if (error && error.response && error.response.status === 401) {
    store.dispatch('logoutWithExpiredSession').then(() => {
      router.push('/');
    });
  }
  return Promise.reject(error);
});

export default HTTP;
