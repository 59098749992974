<template>
  <button
    :class='{
      button: true,
      "button--full-width": (width === "available"),
    }'
    :type='type'
    :title='title'
    :disabled='disabled'
    :id='id'
    :name='name'
    :tabindex='tabIndex'
  >
    <span class='button__icon' v-if='icon'></span>
    <span class='button__text' v-if='showText'>{{text}}</span>
  </button>
</template>

<script>
/**
 * Компонент кнопки (да, она нажимается!).
 * Кнопка используется как триггер для выполнения определённого действия.
 * Кнопка однозначно сообщает пользователю, что  произойдёт после нажатия на неё.
 */
export default {
  name: 'Button',
  props: {
    /**
     * Текст кнопки
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * Поведение кнопки
     * Один из вариантов ['button', 'submit', 'reset']
     */
    type: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'submit', 'reset'].indexOf(value) !== -1;
      },
    },
    /**
     * Текст всплывающей подсказки
     */
    title: {
      type: String,
    },
    /**
     * Управление возможностью взаимодействия с компонентом
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Иконка кнопки
     */
    icon: {
      type: String,
    },
    /**
     * Идентификатор элемента в DOM
     */
    id: {
      type: String,
    },
    /**
     * Имя компонента в Dom
     */
    name: {
      type: String,
    },
    /**
     * Последовательность перехода между контролами при нажатии на Tab
     */
    tabIndex: {
      type: Number,
    },
    /**
     * Управление шириной кнопки. При значении 'available' растягивает кнопку на ширину родителя
     */
    width: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'available'].indexOf(value) !== -1;
      },
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
  .button {
    min-width: 120px;
    padding: 6px 15px;
    border: 1px solid rgba(#d74140, 0.95);
    background-color: rgba(#d74140, 0.95);
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    &--full-width {
      width: 100%;
    }
    &__text {
      color: #ffffff;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
    }
    &:hover {
      border: 1px solid #d74140;
      background-color: #d74140;
    }
    &:focus {
      border-color: #0b1f35f2;
      background-color: #d74140;
    }
    &:active {
      border-color: #a31e1e;
      background-color:#a31e1e;
    }
    &:disabled {
      border: 1px solid rgba(#d74140, 0.25);
      background-color: rgba(#d74140, 0.25);
    }
  }
</style>
