import qs from 'qs';
import router from '@/router/index';
import store from '@/store/index';
import HTTP from '@/api/http-common';
import * as types from './mutation-types';
import { declOfNum } from '@/api/utils';

const state = {
  curQuery: {
    query: '',
    author: '',
    platform: '',
    time: '1week',
    order: 'ptime',
    page: 1,
    dup: 0
  },
  prevQuery: {
    query: '',
    author: '',
    platform: '',
    time: '1week',
    order: 'ptime',
    page: 1,
    dup: 0
  },
  result: {
    count: 0,
    code: 0,
    limit: 0,
    maxPerPage: 0,
    total: 0,
  },
  isLoading: false,
  articles: [],
  error: '',
};

const getters = {
  getArticles: st => st.articles,
  getCurQuery: st => st.curQuery,
  getCurQueryParam: st => fieldName => st.curQuery[fieldName],
  isQueryChanged(st) {
    const curQuery = {
      query: st.curQuery.query,
      author: st.curQuery.author,
      platform: st.curQuery.platform,
      time: st.curQuery.time,
      order: st.curQuery.order,
      dup: st.curQuery.dup,
    };
    const { prevQuery } = st;
    return JSON.stringify(curQuery) !== JSON.stringify(prevQuery);
  },
};

const actions = {
  search({ commit, getters }, isReset = false) {
    const { isQueryChanged, getArticles, getCurQuery } = getters;
    if (isQueryChanged || isReset) {
      commit(types.RESET_CURRENT_QUERY);
      commit(types.TOTAL_RESET);
      commit(types.COUNT_RESET);
      commit(types.LIMIT_RESET);
      commit(types.MAX_PER_PAGE_RESET);
      commit(types.UPDATE_PREV_QUERY, getCurQuery);
    }
    const query = getCurQuery;
    const stateCount = this.state.search.result.count;
    const limit = this.state.search.result.limit || 1000;
    const maxPerPage = this.state.search.result.maxPerPage || 20;
    const maxPage = (limit / maxPerPage) + 1;
    if (
      (query.page === maxPage) ||
      (stateCount !== 0 && stateCount === getArticles.length) ||
      this.state.search.isLoading
    ) {
      return '';
    }

    if (!query.query.length && !(query.author.length && query.platform.length)) {
      return '';
    }

    return new Promise((resolve, reject) => {
      commit(types.SEARCH_REQUEST);
      HTTP.post('/search', qs.stringify(query))
        .then((res) => {
          const result = res.data;
          const resultCode = parseInt(result.result_code, 10);
          if (resultCode === 200) {
            let count = result.total;
            if (count < 1000) {
              document.title = `Крибрум: ${declOfNum(count, ['найдена', 'найдено', 'найдено'])} ${count} ${declOfNum(count, ['публикация', 'публикации', 'публикаций'])}`;
            } else if (count < 1000000) {
              count = Math.round(count / 1000);
              document.title = `Крибрум: ${declOfNum(count, ['найдена', 'найдено', 'найдено'])} ${count} ${declOfNum(count, ['тысяча публикаций', 'тысячи публикаций', 'тысяч публикаций'])}`;
            } else {
              count = Math.round(count / 1000000);
              document.title = `Крибрум: ${declOfNum(count, ['найдена', 'найдено', 'найдено'])} ${count} ${declOfNum(count, ['миллион публикаций', 'миллиона публикаций', 'миллионов публикаций'])}`;
            }


            commit(types.SEARCH_REQUEST_SUCCESS, result);
            if (query.page === 1) {
              commit(types.TOTAL_SET, parseInt(result.total, 10));
              commit(types.COUNT_SET, parseInt(result.count, 10));
              commit(types.LIMIT_SET, parseInt(result.limit, 10));
              commit(types.MAX_PER_PAGE_SET, parseInt(result.max_per_page, 10));
            }

            // Если нам вернулись посты то добавляем страницу
            if (result.posts && result.posts.length) {
              commit(types.UPDATE_PARAM, {
                field: 'page',
                value: query.page + 1,
              });
            }
            /**
             * Странная ситуация когда в базе нашлось одно количество,
             * а затем по какой-то магической причине,
             * удалось получить меньше
             */
            if (
              !result.posts ||
              !result.posts.length ||
              (query.page !== 1 && result.posts.length < maxPerPage)
            ) {
              commit(types.UPDATE_COUNT);
            }
          } else if (resultCode === 401) {
            router.push('/');
            store.commit('CLEAR_PREV_PATH');
            store.dispatch('logoutWithExpiredSession');
          } else if (resultCode === 404 || resultCode === 429) {
            document.title = 'Крибрум: ничего не найдено';
            commit(types.SEARCH_REQUEST_SUCCESS, result);
          }

          resolve(result);
        })
        .catch((err) => {
          commit(types.SEARCH_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
  searchPost({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(types.SEARCH_POST_REQUEST);
      HTTP.get(`/search/post?id=${data.id}&query=${data.query}`)
        .then((res) => {
          commit(types.SEARCH_POST_REQUEST_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          commit(types.SEARCH_POST_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
  searchDublicates({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(types.SEARCH_DUBLICATES_REQUEST);

      const obQuery = {
        ...this.state.search.curQuery,
        ...data,
      };
      for (const key in obQuery) {
        if (obQuery[key] == '' || obQuery[key] == null) {
          delete obQuery[key];
        }
      }

      const urlParams = new URLSearchParams(window.location.search);
      const urlQuery = urlParams.get('query');
      if (urlQuery && urlQuery.length) {
        obQuery.query = urlQuery;
      }

      HTTP.post('/search/dup', qs.stringify(obQuery))
        .then((res) => {
          commit(types.SEARCH_DUBLICATES_REQUEST_SUCCESS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          commit(types.SEARCH_DUBLICATES_REQUEST_FAILURE, err.message);
          reject(err.message);
        });
    });
  },
};
/* eslint-disable no-param-reassign */
const mutations = {
  [types.SEARCH_REQUEST](st) {
    st.isLoading = true;
  },
  [types.SEARCH_REQUEST_SUCCESS](st, data) {
    const articles = [];
    st.articles.forEach((article) => {
      const arrrFiltered = articles.filter(item => item.yauid === article.yauid);
      if (!arrrFiltered.length) {
        articles.push(article);
      }
    });

    if (data && data.posts) {
      data.posts.forEach((article) => {
        const arrrFiltered = articles.filter(item => item.yauid === article.yauid);
        if (!arrrFiltered.length) {
          articles.push(article);
        }
      });
    }

    st.articles = articles;
    st.isLoading = false;
    st.result.code = parseInt(data.result_code, 10);
  },
  [types.SEARCH_REQUEST_FAILURE](st, error) {
    st.isLoading = false;
    st.error = error;
  },
  // Сбрасываем запрос в ноль сохраняя параметры
  [types.RESET_CURRENT_QUERY](st) {
    st.curQuery.page = 1;
    st.articles = [];
    st.result.code = 0;
  },
  [types.UPDATE_PREV_QUERY](st, curQuery) {
    st.prevQuery = {
      query: curQuery.query,
      author: curQuery.author,
      platform: curQuery.platform,
      time: curQuery.time,
      order: curQuery.order,
      dup: curQuery.dup
    };
  },
  // Установка и сброс общего количества сообщений
  [types.COUNT_SET](st, count) {
    st.result.count = count;
  },
  [types.COUNT_RESET](st) {
    st.result.count = 0;
  },
  // Установка и сброс total
  [types.TOTAL_SET](st, total) {
    st.result.total = total;
  },
  [types.TOTAL_RESET](st) {
    st.result.total = 0;
  },
  // Установка и сброс максимального количества сообщений
  [types.LIMIT_SET](st, limit) {
    st.result.limit = limit;
  },
  [types.LIMIT_RESET](st) {
    st.result.limit = 0;
  },
  // Установка и сброс количества сообщений на страницу
  [types.MAX_PER_PAGE_SET](st, maxPerPage) {
    st.result.maxPerPage = maxPerPage;
  },
  [types.MAX_PER_PAGE_RESET](st) {
    st.result.maxPerPage = 0;
  },
  /**
   *
   */
  [types.SEARCH_POST_REQUEST](st) {
    st.isLoading = true;
  },
  [types.SEARCH_POST_REQUEST_SUCCESS](st, data) {
    st.isLoading = false;

    const targetArticle = st.articles.filter(item => item.yauid === data.post.yauid);
    const isArticleExist = targetArticle && targetArticle.length > 0;

    if (isArticleExist) {
      st.articles = st.articles.reduce((prevItem, current) => {
        if (current.yauid === data.post.yauid) {
          current = {
            ...current,
            ...data.post,
            comments: data.comments,
            shares: data.shares,
            nduplicates: data.nduplicates,
            nshares: data.nshares,
          };
        }
        prevItem.push(current);
        return prevItem;
      }, []);
    } else {
      st.articles.push({
        ...data.post,
        comments: data.comments,
        shares: data.shares,
      });
    }
  },
  [types.SEARCH_POST_REQUEST_FAILURE](st, error) {
    st.isLoading = false;
    st.error = error;
  },
  [types.UPDATE_PARAM](st, data) {
    st.curQuery[data.field] = data.value;
  },
  [types.UPDATE_COUNT](st) {
    st.result.count = st.articles.length;
    st.result.total = st.articles.length;
  },

  [types.SEARCH_DUBLICATES_REQUEST](st) {
    st.isLoading = true;
  },
  [types.SEARCH_DUBLICATES_REQUEST_SUCCESS](st, data) {
    st.articles = st.articles.reduce((prevItem, current) => {
      if (current.yauid === data.post.yauid) {
        const dublicates = data && data.duplicates && data.duplicates.length ? data.duplicates : [];
        current = {
          ...current,
          dublicates,
        };
      }
      prevItem.push(current);
      return prevItem;
    }, []);

    st.isLoading = false;
  },
  [types.SEARCH_DUBLICATES_REQUEST_FAILURE](st, err) {
    st.isLoading = true;
    st.error = err;
  },
};
/* eslint-enable no-param-reassign */
export default {
  state,
  getters,
  actions,
  mutations,
};
